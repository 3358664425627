@import "../../../base/mixins.scss";
@import "../../../base/constants.scss";

.image-container {
    max-width:fit-content;
    width:auto;
    height:auto;
    max-height: 815px;

    .single-image{
        max-height: inherit;
        height:inherit;
        margin-top:15px;
        width:100%;

        &.mobile{
            // max-width: 440px;

            @media only screen and (max-width: 600px) {
                //max-width:inherit;
                
             }
        }
    }
    @media only screen and (max-width: $break-tablet) {
        
    }
    
}