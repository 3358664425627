@import "../../base/mixins.scss";

.hotspot-container {

   &.ghost{
      opacity: 0.3 !important;
   }

   &.invisible {
      opacity: 0 !important;
   }
}
.hotspot {
   @include reset-button;
   --size: 2.5rem;
   --min-label-size: 8rem;
   --max-label-size: 16rem;
   --label-gap: 0.5rem;
   width: var(--size);
   height: var(--size);
   min-width: var(--size);
   max-width: var(--size);
   border-radius: 50%;
   background-color: var(--hotspot-color);
   box-shadow: 0 2px 4px rgba(0, 0, 0, 0.25);
   pointer-events: initial;
   position: relative;
}


.not-clickable,
.not-clickable button,
.not-clickable * {
   cursor: default;
   button, button:hover {
      cursor: default;
   }
}


.pulse {
   //animation: pulse-animation 2s infinite;
   position: relative;

   &:before, &:after {
      content: "";
		display: block;
		position: absolute;
		top: 0;
		left: 0;
		z-index: -1;
		background-color: var(--hotspot-color);
		width: 100%;
		height: 100%;
		border-radius: 50%;
		opacity: 0;
		transition: all .25s ease-in-out;
   }

   &:before{
      animation: pulse-animation 1.5s ease-out infinite;
   }
			
	&:after{
      animation: pulse-animation 1.5s ease-out .4s infinite;
   }
			
}
 
@keyframes pulse-animation {
   0% {
      opacity: .7;
		transform: scale(1);
   }
   70% {
      opacity: 0;
		transform: scale(1.5);
   }
   100%{
      opacity: 0;
   }
}