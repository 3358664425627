@import "../base/typography.scss";

:root {
   height: 100dvh;
}
.no-drag {
   //user-drag: none;
   -webkit-user-drag: none;
   user-select: none;
   -moz-user-select: none;
   -webkit-user-select: none;
   -ms-user-select: none;
}

body{
   font-family: "Proxima Nova", "Open Sans", sans-serif;
   height: 100%;
}

.container {
   width: 100%;
   height: 100dvh;
}

button:focus-visible,
a:focus-visible {
   outline: 2px solid var(--brand-color);
}

body::backdrop {
   background-color: var(--background-color);
}

