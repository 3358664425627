@import "../../../../base/mixins.scss";
@import "../../../../base/constants.scss";

.disc-acc-main-cont{
   justify-content: center;

   &.mobile{
      display: none;
   }

   &.desktop{
      display: flex;
      bottom:0;
      right:0;
      width:100%;
      z-index:3;
   }

   @media only screen and (max-width: $break-tablet) {

      &.mobile {
         top:0;
         display: flex;
         right:0;
         width:100%;
         z-index:3;
      }

      &.desktop{
         display: none;
      }
   }
}


.disclaimer-acc-modal {
   padding: 1rem .5rem;
   max-width:660px;
   max-height: 660px;
   background-color: rgba(230, 236, 240, .6);
   border-radius: .5rem .5rem 0 0;
   text-align: left;
   overflow: hidden;
   box-shadow: 0.1rem 0.2rem 0.8rem #00000022;
   // transition: max-height 1s ease-in;

   @media only screen and (max-width: $break-tablet) {
      max-width: 900px;
      background-color: rgba(230, 236, 240, 1);
      border-radius: 0 0 0.8rem 0.8rem;
   }

   &.expanded{
      //max-height: 660px;
      //transition: max-height 1s ease-in;

      .da-grid .da-inner-container {
         max-height: 100%;
         
      }
   }
   
   .expand-button{
      display: flex;
      justify-content: center;

      button{
         border:none;
         margin:0;
         font-size: 1rem;
         padding:.5rem 0 0 0;
         background-color: transparent;
         color:rgb(57, 57, 57);
         
         &:hover{
            color:var(--brand-color);
         }
      }
   }

   .da-grid{
      display:grid;
      grid-template-columns: auto auto;

      .da-icon-cont {
         padding-left: .5rem;
         
      }

      .da-inner-container{
         overflow: hidden;
         max-height: 100%;
         //transition: max-height .5s;
         height:100%;
         //height:calc(100% + 1px);
   
         &__text {
            font-size: .75rem;
            text-align: left;
            padding:0 1rem;
            pointer-events: none;
            
         }
      }

      .da-modal__heading {
         font-size: 1.1rem;
         font-weight: 600;
         padding:0 1rem;
         margin-bottom: 1rem;
      }

      .da-inner-container::-webkit-scrollbar {
         -webkit-appearance: none;
         width: 4px;
      }
      .da-inner-container::-webkit-scrollbar-thumb {
         border-radius: 4px;
         background-color: rgba(0, 0, 0, .4);
         -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, .5);
      } 
   }
}