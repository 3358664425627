@import "../../../base/constants.scss";

.model-selector-title-bar {
    background-color: rgba(255, 255, 255, 0.85);
    backdrop-filter: blur(4px);
    position: absolute;
    bottom: 100%;
    display: flex;
    align-items: center;
    border-radius: 0.8rem;
    overflow: visible;
    min-width: 100%;
    max-width: 100%;
    justify-content: center;
    color: black;
    box-shadow: 0px 0px 5px #00000044;
    z-index: 4;

    .text-container {
        padding: 1.2rem 1rem;
        font-size: 1rem;
        font-weight: 600;
        display: flex;
        flex-direction: column;
        gap: 0.3rem;
        width: max-content;
        .product-name {
            font-weight: normal;
            // color: var(--brand-color);


         }
         .variant-name {
               font-weight: bold;
            //    color: var(--title-color);

            }
    }

    @media only screen and (max-width: $break-tablet) {
            display: none;
    }
}