@import "../../../base/mixins.scss";

.information-modal {
   padding: 2rem 3rem;
   font-weight: 600;

   &__title {
      font-size: 1.75rem;
      text-align: center;
      margin-block-end: 2rem;
   }
}

.desktop-instructions {
   display: grid;
   gap: 0.75rem 1.5rem;
   align-items: center;
   justify-items: center;
   grid-template-rows: repeat(3, auto);
   grid-template-columns: repeat(2, 1fr);

   p:first-of-type {
      grid-column: 1 / 2;
      grid-row: 2 / 3;
   }

   img {
      width: 50%;
   }
}

.mobile-instructions {
   display: grid;
   gap: 0.75rem 1.5rem;
   align-items: center;
   justify-items: center;
   grid-template-rows: repeat(3, auto);
   grid-template-columns: repeat(2, 1fr);

   p:first-of-type {
      grid-column: 1 / 2;
      grid-row: 2 / 3;
   }

   img {
      width: 50%;
   }
}
