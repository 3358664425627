@import "../../base/constants.scss";



.product-selector-background {
   position: absolute;
   top: 0;
   left: 0;
   width: 100%;
   height: 100%;
   // z-index: 0;

}

.product-selector {
   position: absolute;
   top: 0;
   display: flex;
   flex-direction: row;
   justify-content: center;
   --thumb-size: 12rem;
   height: 100%;
   width: 100%;
   opacity: 100%;
   transition: opacity 0.2s;
   // position: relative;
}

.product-selector-hidden {
   transition: bottom 500ms cubic-bezier(0.175, 0.885, 0.32, 1.275), visibility 0ms ease 500ms;
   bottom: -12rem !important;
   opacity: 0;
   transition: opacity 0.2s;
}

.product-selector .list-container {
   /* background-color: rgba(255, 255, 255, 0.5); */
   height: 100%;
   width: 100%;
   margin: 0;
   padding: 0;
   position: relative;
   display: flex;
   flex-direction: column;
   justify-content: center;
}

.product-selector ul {
   list-style-type: none;
   display: flex;
   flex-direction: row;
   align-items: center;
   justify-content: flex-start;
   gap: 1rem;
   padding: 0 1rem;
   pointer-events: initial;
   scrollbar-width: none;
   overflow-y: visible;
   // z-index: 4;
}

.product-selector li {
   width: var(--thumb-size);
   height: var(--thumb-size);
   border-radius: 1rem;
   padding: 0.5rem;
   flex: none;
   pointer-events: initial;
   z-index: 3000;

}

.product-selector ul::-webkit-scrollbar {
   height: 0.3rem;
   bottom: -100px;
   display: none;
}

.product-selector ul::-webkit-scrollbar-track {
   background-color: rgb(200, 200, 200);
   border-radius: 12rem;
   margin: 0 0.5rem;
}

.product-selector ul::-webkit-scrollbar-thumb {
   background-color: rgb(149, 149, 149);
   border-radius: 12rem;
}

.product-selector ul::-webkit-scrollbar-thumb:hover {
   background-color: var(--brand-color);
}

.grab-scroll {
   overflow-x: auto;
}


@media only screen and (max-width: $break-tablet) {

   .product-selector {
      --thumb-size: 9rem;
      --padding-vertical: 2rem;
      --margin-bottom: 3.5rem;   
      height: calc(100% - var(--margin-bottom));
      position: absolute;
      overflow-y: scroll;

      ul {
         flex-direction: column;
         max-width: 90vw;
         margin-bottom: var(--margin-bottom);
         padding: var(--padding-vertical) 0;
         justify-content: flex-start;
      }

      .center-vertically{
         justify-content: center;
      }

      // --mask: linear-gradient(0deg, rgba(0,0,0,0) 0%, rgba(0,0,0,1) 5%, rgba(0,0,0,1) 95%, rgba(0,0,0,0) 100%) 100% 50% / 100% 100% repeat-x;
      // -webkit-mask: var(--mask); 
      // mask: var(--mask);

   }

   .product-selector li {
      width: 100%;
      height: var(--thumb-size);
      border-radius: 1rem;
      padding: 0.5rem;
      flex: none;
      display: flex;
      flex-direction: row;
   }
}