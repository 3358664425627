/* Box sizing rules * remove default padding/margins */
*,
*::before,
*::after {
   box-sizing: border-box;
   margin: 0;
   padding: 0;
}

/* Ensures font size is 100% of base font size set in browser */
html {
   font-size: 100%;
   scroll-behavior: smooth;
}

/* Remove list styles on ul, ol elements with a list role, which suggests default styling will be removed */
ul[role="list"],
ol[role="list"] {
   list-style: none;
}

/* Set core root defaults */
html:focus-within {
   scroll-behavior: smooth;
}

/* Set core body defaults */
body {
   text-rendering: optimizeSpeed;
}

/* Set a sensible default line height */
p {
   line-height: 1.2;
}

/* <a> elements with a class or child of <li> with class, remove default styling */
a[class],
li[class] a {
   text-decoration: none;
}

/* Make images easier to work with */
img,
picture {
   max-width: 100%;
   display: block;
}

/* Inherit fonts for inputs and buttons */
input,
button,
textarea,
select {
   font: inherit;
}

/* Removes default outline */

button {
   outline: none;
}

/* Button hover state */
button:hover {
   cursor: pointer;
}

/* Remove all animations, transitions and smooth scroll for people that prefer not to see them */
@media (prefers-reduced-motion: reduce) {
   html:focus-within {
      scroll-behavior: auto;
   }

   *,
   *::before,
   *::after {
      animation-duration: 0.01ms !important;
      animation-iteration-count: 1 !important;
      transition-duration: 0.01ms !important;
      scroll-behavior: auto !important;
   }
}
