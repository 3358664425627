@import "../../../base/mixins.scss";

#qr-code-modal .modal__inner {
   display: flex;
   flex-direction: column;
   gap: 1rem;

   text-align: left;
   color: black;
   padding: 1rem;
   width: calc(260px + 6rem);
   --surround-height: 168px;
   --bg-diameter: calc(var(--surround-height) - 15px);


   .qr-code-section {
      padding: 1rem 0;

   }
   .container {
      height: var(--surround-height);
      width: 100%;
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
   }

   .surround {
      height: var(--surround-height);
      position: absolute;
      margin: 0 auto;
      path {
         fill: var(--brand-color);
      }

      circle {
         stroke: var(--brand-color);
      }

   }
   .bg {
      background-color: orange;
      height: var(--bg-diameter);
      width: var(--bg-diameter); 
      border-radius: 50%;
      background-image: url("../../../assets/qr-backgound.png");
      background-repeat: no-repeat;
      background-position: center;
      background-size: 109%;
      filter: brightness(1);
   }

   .qr-code {
      position: absolute;
      border: 2px solid white;
      box-sizing: content-box;
      z-index: 12;
   }

   .qr-code-modal {

      &__title {
         text-align: center;
         font-size: 1.75rem;
      }

      &__qr {
         margin-block: 2.25rem;
         position: relative;

         &::after {
            content: "";
            display: block;
            width: 10rem;
            height: 10rem;
            opacity: 0.2;
            @include abs-center;
            border-radius: 100%;
            background-color: black;
         }
      }

      &__qr .qr-code {
         @include abs-center;
         outline: solid 3px white;
      }

      &__qr .qr-surround {
         margin-inline: auto;
         display: block;
         z-index: 12;
         height: 168px;

         path {
            fill: var(--brand-color);
         }

         circle {
            stroke: var(--brand-color);
         }
      }

      &__footer {
         gap: 0.2rem 1rem;
         display: grid;
         text-align: left;
         align-items: center;
         grid-template-areas: "img strong" "img p";
         grid-template-columns: repeat(2, auto);
      }

      &__footer img {
         grid-area: img;
      }

      &__footer strong {
         font-size: 1.25rem;
         grid-area: strong;
      }

      &__footer p {
         font-size: 1rem;
         line-height: 1;
         grid-area: p;
      }
   }
}

.top-bar {
   display: flex;
   flex-direction: row;
   min-height: 2.5rem;
   max-height: 3rem;
   align-items: center;
   gap: 0.75rem;
   width: 100%;

   button {
      height: 2.5rem;
      width: 4rem;
      padding: 0;
      margin-left: auto;
   }
}