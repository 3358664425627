@import "../../../base/constants.scss";

.hotspot-container {
   .__label {
      .__content {
         .__video-content {
            aspect-ratio: calc(16 / 9);
            padding: 0;
            margin: 0.1rem 0;
            pointer-events: initial;
            & video {
               width: 100%;
               height: 100%;

               @media only screen and (max-width: $break-tablet) {
                  max-width: 80vw;
               }
            }
         }



         .hotspot__video-text-content, .modal__video-text-content {
            margin-bottom: 0.2rem;
            font-size: 1rem;
            line-height:1.2rem !important;
         }
      }
   }
}
