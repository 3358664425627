.languageSwitcher {
    min-height: 3rem;
    display: flex;
    position: relative;
    width:72px;
    align-items: center;
    border-radius: 0.8rem;
    background-color: var(--clr-grey300);
    transition: background-color 350ms ease;
    pointer-events: initial;

    .lang {
        display: flex;
        flex-direction: column;
        align-items: center;
        width:100%;
    
        .firstElement {
            border: 2px solid var(--clr-grey600);
            border-radius: 0.8rem;
            margin:0.6rem 0;
        }
    
        .dropdown {
            position: relative;
            left: 0;
            display: none;
            list-style: none;
            width:100%;
            padding: 0.768rem;
        }

        .dropdown-background {
            width: 100%;
            min-height: 0;
            position: absolute;
            top:0;
            display: flex;
            flex-direction: column;
            align-items: center;
            border-radius: 0.8rem;
            gap: 0.5rem;
        }
        
    }
}

@keyframes rotating {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.languageOptions {
    width: 1.5rem;
    height: 1.5rem;
    background-size: 200%;
    background-position: center;
    background-repeat: no-repeat;
    border-radius: 0.8rem;
    border: 2px solid transparent;
    transition: border 350ms ease;

    &:hover {
        border: 2px solid var(--brand-color);
    }

}

.languageOptions.loCurrent{
    width:1.5rem;
    height:1.5rem;
    border: 2px solid #d15400;

    &:hover{
        border: 2px solid #d15400 !important;
    }

    .loCurrentFilter{
        height:100%;
        width:100%;
        background-color:rgba(0,0,0,0.4);
        border-radius:0.8rem;
    }
}

//////////////////////////////////////////////////
///////////// Element under hover ////////////////
//////////////////////////////////////////////////

.languageSwitcher:hover {
    background-color: var(--clr-grey400);

    .lang .firstElement {
        border: 2px solid var(--brand-color);
    }

    .dropdown {
        background-color: #f5f5f5;
        transition: height 350ms ease;


        .languageOptions {
            transition: all 350ms ease;

            &:hover {
                transform: scale(120%);
                border: 2px solid var(--brand-color);
                border-radius: 0.8rem;
            }
        }

    }
}