.App {
    font-family: sans-serif;
    text-align: center;
  }
  
  .modal__bg{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color:var(--clr-grey700);
    opacity: 1;
    animation-timing-function: ease-out;
    animation-duration: 0.3s;
    animation-name: modal-video;
    -webkit-transition: opacity 0.3s ease-out;
    -moz-transition: opacity 0.3s ease-out;
    -ms-transition: opacity 0.3s ease-out;
    -o-transition: opacity 0.3s ease-out;
    transition: opacity 0.3s ease-out;
    z-index: 100;
  }

  .modal__video-text-content{
    margin-top: 0.5rem;
    font-size: 1.1rem;
    font-weight: 400;
    line-height: 1.2rem;
  }

  .no-pointer-events {
    pointer-events: none;
  }
  
  .modal__align {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
  }
  
  .modal__content {
    width: 880px;
    border-radius: 20px;
    color: #000;
    margin: 0rem 2rem;
  }
  .modal__title-bar__buttons{
    display: flex;
    gap: 0.8rem;
    height: 100%;
  }

  .modal__close{
    inset: var(--inset) var(--inset) auto auto;
    background-color: var(--clr-grey450);
    transition:background-color 0.3s;
    padding:1rem;
    width: 4.5rem;

    &:hover{
        background-color: var(--clr-grey300) !important;
    }

    img{
        max-width: 1.4rem;
        max-height: 1.3rem;
    }
  }
  
  .modal__video-align {
    position: relative;
    height: inherit;
    background-color:white;
    border-radius: 1rem;
    padding: 0.8rem;
  }
  
  .modal__title-bar {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 0.8rem;
    // height: 3rem;
    gap: 0.8rem;
    font-size: max(1rem, 100%);

    .text {
      display: flex;
      align-items: flex-start;
      flex-direction: column;
      text-overflow: ellipsis;

    }

  }
  .modal__video-style {
    //border-radius: 20px;
    z-index: 100;
    width:100%;
    height: 426.9375px;
    transition: height 0.3s;
    pointer-events: initial;

    html .showfocus{
        border-radius: 0;
        align-items: normal;
    }
  }
  
//   @media screen and (max-width: 800px) {
//     .modal__content {
//       //margin: 0rem 1rem;
//       width: 100%;
//     }
//     .modal__video-style {
//       width: 100%;
//     }
//   }

//   @media screen and (max-width: 540px) {
//     .modal__content {
//       max-height: 270px;
//     }
//   }

   @media screen and (max-width: 375px) {
    .modal__video-align h2 {
      font-size:1.2rem;
    }
}
  