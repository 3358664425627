@import "../../base/mixins.scss";
@import "../../base/constants.scss";

// .disc-visible{
//    @media only screen and (max-width: $break-tablet) {
//       padding-top: 170px !important;
//    }
// }

.action-bar {
   margin: 1rem 0;
   height: 3rem;
   width: 100%;
   display: flex;
   flex-direction: row;
   justify-content: space-between;
   padding: 0 1rem;
   gap:0.75rem;
   overflow-x: clip;
   overflow-y: visible;

   &__left {
      flex: 1;
      gap: 0.75rem;
      height: 100%;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
   }

   &__right {
      flex: 1;
      gap: 0.75rem;
      height: 100%;
      display: flex;
      flex-direction: row;
      justify-content: flex-end;   
   }

   &__container{
      display: flex;
      flex-flow: column;
      justify-content: flex-end;
   }

   &__lower{
      z-index: 3;
      height: 3rem;
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding-inline: 1rem;
      overflow-x: clip;
      overflow-y: visible;
   }

   &__lower-right{
      margin-left: auto;
      flex: 1;
      gap: 0.75rem;
      height: 100%;
      display: flex;
      flex-direction: row;
      justify-content: flex-end;   
   }
}

.action-bar-list-container {
   width: 100%;
   flex: 2;
   display: flex;
   flex-direction: row;
   justify-content: center;
   z-index: 3;

   @media only screen and (max-width: $break-tablet) {
      &.desktop {
         display: none;
      }
   
      &.mobile {
         display: flex;
      }   
   }
}

.action-bar-list {
   height: 100%;
   max-width: 50vw;
   display: flex;
   flex-direction: row;
   list-style-type: none;
   padding: 0;

   .action-btn {
      width: 7.5rem;
      padding: unset;
   }

   &.mobile {
      display: none;
   }

   .action-btn {
      border-radius: 0;
   }
   
   >.action-btn:first-child {
      border-top-left-radius: 0.8rem;
      border-bottom-left-radius: 0.8rem;
   }
   
   >.action-btn:last-child {
      border-top-right-radius: 0.8rem;
      border-bottom-right-radius: 0.8rem;
   }

   // Tablet viewport
   @media only screen and (max-width: $break-tablet) {
      z-index: 2;
      width: 100%;
      max-width: 100%;

      li {
         width: 100%;
      }

      .action-btn {
         flex: 1;
      }

      >.action-btn:first-child {
         border-top-left-radius: 0;
         border-bottom-left-radius: 0;
      }

      >.action-btn:last-child {
         border-top-right-radius: 0;
         border-bottom-right-radius: 0;
      }

      &.desktop {
         display: none;
      }

      &.mobile {
         display: flex;

         .action-btn {
            height: 3.5rem;
         }
      }
   }
}

