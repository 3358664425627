@import "../../../base/constants.scss";

.modal-hotspot-bg{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color:var(--clr-grey700);
    opacity: 1;
    animation-timing-function: ease-out;
    animation-duration: 0.3s;
    animation-name: modal-video;
    -webkit-transition: opacity 0.3s ease-out;
    -moz-transition: opacity 0.3s ease-out;
    -ms-transition: opacity 0.3s ease-out;
    -o-transition: opacity 0.3s ease-out;
    transition: opacity 0.3s ease-out;
    z-index: 100;

    .modal-hotspot-content {
        height: 100%;
        border-radius: 20px;
        color: #000;
        display: flex;
        align-items: center;
        justify-content: center;
      }

    .modal-hotspot {
        background-color:white;
        border: 1px solid #ddd;
        border-radius: 0.8rem;
        padding: 1.2rem;
        backdrop-filter: blur(4px);
        -webkit-backdrop-filter: blur(4px);
        
        @media only screen and (max-width: 600px) {
          max-width: 540px;
        }

      }

      & .__title-bar{
        display: grid;
        grid-template-columns: 0.99fr auto;


        & .__title{
          display: flex;
          align-items: center;

          p{
            font-weight: 700;
            font-size: 18px;
            margin:0;
            padding-left:10px;
          }
        }



        .modal-hotspot-close {
          inset: var(--inset) var(--inset) auto auto;
          background-color: var(--clr-grey450);
          transition:background-color 0.3s;
          padding:1rem 1.5rem;

          &:hover{
            background-color: var(--clr-grey300) !important;
          }

          img{
            max-width: 1.4rem;
            max-height: 1.3rem;
          }
        }
      }

      & .__content-link {
        color: #18a0fb;
        pointer-events: initial;
        
        &:hover {
           text-decoration: underline;
        }
     }

          @media only screen and (max-width: $break-tablet) {
              .modal-hotspot {
                  //width: 90dvw;
                  max-width: 90dvw;
                  max-height: 90dvh;
              }
          }
    
  }


