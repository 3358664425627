#tutorial-modal {
    .modal__inner {
        max-width: 95dvh;
        max-height: 95dvh;
    }
    
}
.tutorial-modal__inner {
    display: flex;
    flex-direction: column;
    max-width: initial;
    max-height: initial;
    align-items: center;
    gap: 1rem;
    padding: 1rem;
  
    .top-bar{
        display: flex;
        flex-direction: row;
        min-height: 2.5rem;
        height: 2.5rem;
        align-items: flex-start;
        gap: 0.75rem;
        width: 100%;
        // color: var(--clr-grey600);

        .tutorial-modal__title {
            flex: 1;
            min-height: 100%;
            width: 100%;
            text-align: left;
            display: flex;
            flex-direction: row;
            align-items: center;
        }

        h2{
            font-size: 1.5rem;
            font-size: clamp(0.8rem, 5vh, 1.5rem);


        }
        button {
            height: 2.5rem;
            width: 4rem;
            padding: 0;
        }
                
    }
    .bottom-bar {
        display: flex;
        flex-direction: row;
        height: fit-content;
        min-height: 2.5rem;
        gap: 0.75rem;
        width: 100%;
        align-items: flex-end;
        justify-content: space-between;
        // color: var(--clr-grey600);

        @media screen and (max-width: 650px) {
            align-items: center;
        }

        p {
            font-size: 1rem;
            font-weight: 600;
            text-align: left;
            max-width: 100%;
            font-size: 0.8rem;

            @media screen and (min-width: 400px){
                font-size: 0.9rem;
            }
            @media screen and (min-width: 600px){
                font-size: 1rem;
            }
        }

            .tutorial-modal__info {
                display: flex;
                flex-direction: column;
                align-items: flex-start;

                @media (max-width: 600px) and (orientation: portrait) {
                &.join-paragraph {
                    display: block;
                    text-align: left;

                    p {
                        // display: inline-block;
                    }
                }
            }
        }

        @media (max-height: 600px) and (orientation: landscape) {
            display: none;
        }



        // /* Mobile media query for devices with screen width less than 768px */
        // @media (max-width: 768px) {
        //     p {
        //     font-size: 0.875rem; /* Adjust font size for mobile (e.g., 14px) */
        //     }
        // }
    }
    .logo {
        max-width: 7.5rem;
        max-height: 2rem;

        a {
            display: block;
            width: 100%;
            height: 100%;
        }

        svg {
            height: 100%;
            width: 100%;
        }
        
    }

    .tutorial-modal__content {
        // max-height: max(70vh, 70%)
    }
    
    .tutorial-video {
        //height: unset;
        // max-height: min(calc(50rem - 10rem), calc(90dvh - 10rem));
      }
      
}