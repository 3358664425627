@import "../../../base/mixins.scss";

.handoff-modal {
   padding: 1rem;
   line-height: 1;
   width: 21rem;
   gap: 1.5rem;
   display: flex;
   flex-direction: column;

   &__title {
      font-size: 1.75rem;
      text-align: center;
   }

   .bottom-bar {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
   }
   
}
