@import "../../../base/mixins.scss";
@import "../../../base/constants.scss";

.hotspot-container {
   & .__label{
      position: absolute;
      --gap: 1rem;
      width: max-content;

      &.label-right{
         right: -50%;
         top: 50%
      }
      &.label-left{
         left: -50%;
         top: 50%
      }
      &.label-bottom{
         top:calc(100% + var(--gap));
         left: 50%;
      }
      &.label-top{
         bottom:calc(100% + var(--gap));
         left: 50%;
      }
      & .__content {
         display: flex;
         flex-direction: column;
         align-items: flex-start;
         gap: 0.5rem;
         --label-size: 12rem;
         overflow: hidden;
         max-width: 20rem;
         background-color: rgba(255, 255, 255, 0.85);
         backdrop-filter: blur(4px);
         color: black;
         border-radius: 0.8rem;
         border: 1px solid #dddddd;
         padding: 1.2rem;
         left: calc(var(--size) + 0.5rem);
         top: 0;
         text-align: left;

         @media only screen and (max-width: $break-tablet) {
            max-width: min(80vw, 20rem);
         }

         &._video {
            width: 32rem;
            max-width: 32rem;

            @media only screen and (max-width: $break-tablet) {
               max-width: 80vw;
            }

         }

         & .__title-bar {
            display: flex;
            flex-direction: row;
            justify-content: space-evenly;
            gap: 0.5rem;

            & .__toggle {
               color: #18a0fb;
               pointer-events: initial;
            }
         }
         & .__title {
            font-size: 1.1rem;
            font-weight: 600;
         }
         & .__body {
            font-size: 1rem;
            line-height: 1.2rem;
            font-weight: 400;
         }
         & .__content-link {
            color: #18a0fb;
            pointer-events: initial;
            font-weight: bold;
            
            &:hover {
               text-decoration: underline;
            }
         }
      }
   }
}

.video-popup-button{
   background-color: transparent;
   border:none;
}