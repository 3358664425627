

.button--close {
   --inset: 0rem;
   position: absolute;
   padding: 0.5rem 1rem;

   svg {
      font-size: 1.5rem;
      font-size: 4rem;

   }

   &:hover {
      background-color: var(--clr-grey500);
   }
}
