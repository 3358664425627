@import "../../base/constants.scss";

.watermark {
   width: 6rem;
   opacity: 0.25;
   --inset: 1rem;
   position: absolute;
   bottom: var(--inset);
   fill: var(--clr-grey600);
   inset: auto var(--inset) var(--inset) auto;
}

@media only screen and (max-width: $break-tablet) {
   .reydar-logo {
      inset: auto var(--inset) var(--inset) auto;
   }
}
