@import "../../base/constants.scss";

.custom-logo-container {
   position: absolute;
   top: 0;
   width: 100%;
   height: 100%;
   transition: 0.6s filter;
   transition-delay: 0.03s;
}
.custom-logo {
   width: var(--custom-logo-width);
   min-width: 10rem;
   max-width: 50%;
   opacity: var(--custom-logo-opacity);
   fill: var(--clr-grey600);
   max-height: fit-content;
   position: absolute;

   &.position-anchor-top{
      top: var(--custom-logo-inset-y);
   }
   &.position-anchor-bottom{
      bottom: var(--custom-logo-inset-y);
   }



   &.left {
      left: var(--custom-logo-inset-x);
   }
   
   &.right {
      right: var(--custom-logo-inset-x);
   }

   &.center, &.centre {
      left: 0;
      right: 0;
      margin: auto;
   }

}
@media only screen and (max-width: $break-tablet) {
   .custom-logo{
      min-width: initial;
      max-width: calc((100vw - (2 * var(--custom-logo-inset-x))) * 0.48);
      width: var(--custom-logo-width-mobile);
   }
}
