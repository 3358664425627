@import "../../base/mixins.scss";
@import "../../base/constants.scss";

:root{
   --title-color: var(--clr-grey600);
   --title-opacity: "100%";
}

.model-title {
   inset: auto auto var(--inset) var(--inset);
   color: var(--title-color);
   opacity: var(--title-opacity);
   --inset: 1rem;
   display: flex;
   flex-direction: row;
   flex-wrap: wrap;
   gap: 0.3rem;
   z-index: 1;
   max-width: 50%;
   font-size: 1.35rem;
   margin-left: 1rem;
   margin-bottom: 1rem;
   .product-name {
      font-weight: bold;

   }
   .variant-name {
         font-weight: normal;

      }

}

@media only screen and (max-width: $break-tablet) {
   .model-title {
      bottom: 4.5rem;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
   }

   .model-title h1 {
      font-size: 1.1rem;
   }

   .watermark + .model-title {
      inset: auto auto 4.5rem var(--inset);
      width: calc(100vw - 7rem);
   }
}

@media only screen and (max-width: 380px) {
   .model-title {
      bottom: 4.5rem;
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
   }
}
