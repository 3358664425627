#lazy-load-poster {
   position: absolute;
   left: 0;
   right: 0;
   top: 0;
   bottom: 0;
   background-size: contain;
   background-repeat: no-repeat;
   background-position: center;
   background-size: contain;
   background-color: white;
}

.poster-content {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.poster-tip {
   background-color: rgba(#555760, 0.5);
   border-radius: 1rem;
   display: flex;
   align-items: center;
   justify-content: center;
   font-size: 1.5rem;
   height: 6rem;
   padding: 1rem;

}
