.dot {
    display: none;
  }


  
  .dim {
    background: var(--dimensions-label-color);
    border-radius: 10px;
    border: 2px solid var(--dimensions-border-color);
    box-sizing: border-box;
    color: var(--dimensions-text-color);
    display: block;
    font-family: Futura, Helvetica Neue, sans-serif;
    font-size: 1em;
    font-weight: 700;
    max-width: 128px;
    overflow-wrap: break-word;
    padding: 0.5em 1em;
    position: absolute;
    width: max-content;
    height: max-content;
    transform: translate3d(-50%, -50%, 0);
    pointer-events: none;
    --min-hotspot-opacity: 0;
  }
  
  @media only screen and (max-width: 800px) {
    .dim {
      font-size: 3vw;
    }
  }
  
  .dimensionLineContainer {
    pointer-events: none;
    display: block;
  }
  
  .dimensionLine {
    stroke: var(--dimensions-line-color);
    stroke-width: 2;
    stroke-dasharray: 6 2;
  }
  
  .backface {
    opacity: 0.2;
  }
  
  .hide {
    display: none;
  }

  .hide-dimensions {
    display: none;
  }
  