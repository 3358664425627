@import "../../../base/mixins.scss";

.action-btn {
   background-color: var(--clr-grey300);
   transition: background-color 350ms ease;
   transition: box-shadow 1s ease;
   //font-family: "Proxima Nova";
   font-size: 1rem;
   border: none;
   position: relative;
   border-radius: 0.8rem;
   padding: 1.5rem;
   color: black;
   display: flex;
   align-items: center;

   @media screen and (max-width: 460px) {
      //padding:0.6rem 0.8rem;
   }
   @media screen and (max-width: 320px) {
      //padding:0.6rem;
   }

   &__icon {
      //transform: scale(0); // addition for otrivin
      max-width: 1.4rem;
      max-height: 1.3rem;
      margin-inline: auto;
      width: 100%;

      @media screen and (max-width: 460px) {
         max-width: 1.3rem;
         max-height: 1.2rem;
      }
   }
   &__label {
      font-weight: bold;
      color: var(--brand-color);
   }
   svg {
      font-size: 1.25rem;
      color: var(--reydar-grey);
      max-width:1.4rem;
      width:100%;
      transition: all 1s ease;
   }
}

// Hover states
@media (hover: hover) {
   .action-btn:hover:not(button[disabled]) {
      background-color: var(--clr-grey400);
   }
   .action-btn:active:not(button[disabled]) {
      background-color: var(--clr-grey500);
   }
   .action-bar-list .action-btn:hover:not(button[disabled]) {
      background-color: var(--clr-grey400);
      .action-btn__label {
         font-size: revert;
      }
      .action-btn__icon {
         transform: scale(0);
      }
   }
}

// Disabled state
button[disabled] {
   cursor: default;
   filter: grayscale(1) contrast(0.9);

   img {
      opacity: 0.4;
   }
}

// Action buttons within Action List
.action-bar-list .action-btn {
   &__icon {
      transition: transform 300ms ease;
   }
   &__label {
      transition: font-size 300ms ease;
      @include abs-center;
      font-size: 0;
      //font-size: 1rem; //addition for otrivin
   }
   &-active {
      background-color: var(--clr-grey400);
      .action-btn__label {
         font-size: revert;
      }
      .action-btn__icon {
         transform: scale(0);
      }
   }
}


.action-bar-list .noIcons {
   img{
      display: none;
   }
   *.action-btn__label{
      font-size: initial;
      color:var(--clr-grey600);
   }

   &.action-btn-active{
      background-color: var(--clr-grey400);
      *.action-btn__label{
         color:var(--brand-color);
      }
   }

   @media (hover: hover){
      &:hover{
         *.action-btn__label{
            color:var(--brand-color);
         }
      }
   }
}

////////////////////////////////////
/// 

.pulser {
   //animation: pulser-animation 2s infinite;
   //background-color: var(--clr-grey350);
   box-shadow: 0px 0px 6px 0px var(--brand-color);
   position: relative;

   &:before, &:after {
      content: "";
		display: block;
		position: absolute;
		top: 0;
		left: 0;
		z-index: -2;
		background-color: var(--brand-color);
		width: 100%;
		height: 100%;
		border-radius: 0.8rem;
		opacity: 0;
		transition: all .25s ease-in-out;
   }

   &:before{
      animation: pulser-animation 3.5s ease-out infinite;
   }
			
	&:after{
      animation: pulser-animation 3.5s ease-out .4s infinite;
   }
			
}
 
@keyframes pulser-animation {
   0% {
      opacity: .0;
		transform: scale(1);
   }
   35%{
      opacity: .05;
      transform: scale(1.1);
   }
   70% {
      opacity: .1;
		transform: scale(1.2);
   }
   100%{
      opacity: 0;
   }
}

.highlight {
   box-shadow: 0px 0px 5px 0px var(--brand-color);
}

.highlight svg path{
   fill:var(--brand-color);
}