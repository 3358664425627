@import "../../../base/mixins.scss";


.disclaimer-modal {
   padding: 2rem 1rem;
   font-weight: 600;
   max-width:660px;
   max-height: 600px;
   margin:0 25px;

   &__heading {
      font-size: 1.75rem;
      text-align: center;
      padding-top:30px;
      margin:0;
   }

   .disclaimer-inner-container{
      overflow-y: scroll;
      overflow-x: hidden;
      -webkit-overflow-scrolling: touch;
      margin-top: 2rem;
      //overflow: -moz-scrollbars-vertical;
      max-height: 453px;
      height:calc(100% + 1px);
   
      &__text {
         font-size: 1rem;
         text-align: center;
         padding:0 1rem;
         pointer-events: none;
      }
   }

   .disclaimer-inner-container::-webkit-scrollbar {
      -webkit-appearance: none;
      width: 4px;
   }
   .disclaimer-inner-container::-webkit-scrollbar-thumb {
      border-radius: 4px;
      background-color: rgba(0, 0, 0, .4);
      -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, .5);
   } 
}