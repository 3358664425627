/*Viewer setup*/
:root {
   --background-color: white;
   --background-image: none;
   --background-image-fit: cover;
   --background-image-repeat: no-repeat;
   --background-image-position: center;
   --background-image-padding: 0 0 0 0;
}

.reydar-model-viewer {
   width: 100dvw;
   height: 100dvh;
   max-height: -moz-available;
   max-height: -webkit-fill-available;
   background: var(--background-color);
   background-repeat: no-repeat;
   font-weight: normal;
   color: white;
   overflow: hidden;
}

model-viewer {
   width: 100vw;
   height: 100%;
   max-height: -moz-available;
   max-height: -webkit-fill-available;
   position: absolute;
   top: 0;
   left: 0;
}

.background-image {
   width: 100%;
   height: 100%;
   background-image: var(--background-image);
   background-size: var(--background-image-fit);
   background-repeat: var(--background-image-repeat);
   background-position: var(--background-image-position);
   background-origin: content-box;
   padding: var(--background-image-padding);
}
