@import "../../../base/mixins.scss";
@import "../../../base/constants.scss";

.contact-modal {
   padding: 1rem;
   font-weight: 600;
   max-height: 95dvh;
   max-width: 95dvw;

   width: 25rem;
   //max-width:30%;
   //width:31.2rem;

   //@media only screen and (max-width: $break-tablet) {
   //max-width:70%;
   //}
   //@media only screen and (max-width: $break-mobile) {
   //max-width:90%;
   //}

   &__title {
      font-size: 1.75rem;
      text-align: left;
   }

   .top-bar {
      display: flex;
      flex-direction: row;
      min-height: 2.5rem;
      max-height: 3rem;
      align-items: center;
      gap: 0.75rem;
      width: 100%;
      margin-bottom: 2rem;

      .contact-modal__title {
         flex: 1;
         min-height: 100%;
         width: 100%;
         text-align: left;
         display: flex;
         flex-direction: row;
         align-items: center;
      }

      button {
         height: 2.5rem;
         width: 4rem;
         padding: 0;
      }
   }
}

.desktop-contact-inf {
   display: grid;
   gap: 0.75rem 1.5rem;
   align-items: center;
   justify-items: center;
   grid-template-rows: repeat(3, auto);
   grid-template-columns: repeat(2, minmax(0, 1fr));
   grid-auto-flow: column;

   p,
   a {
      overflow-wrap: anywhere;
   }

   a {
      color: var(--brand-color);
   }

   img {
      width: 50%;
   }
}

.mobile-contact-inf {
   display: grid;
   gap: 0.75rem 1.5rem;
   align-items: center;
   justify-items: center;
   grid-template-rows: repeat(3, auto);
   grid-template-columns: repeat(2, 1fr);

   p a {
      color: var(--brand-color);
   }

   img {
      width: 50%;
   }
}

.bottom-inf {
   font-size: 1.2rem;
   text-align: center;
   padding-top: 30px;

   p a {
      color: var(--brand-color);
   }
}

///////////
///

.base-elem {
   display: block;
   text-align: left;
   border-radius: 1rem;
   padding: 1rem;
   background-color: #f3f3f3;


   .contact-row:nth-of-type(4) {
      padding-bottom: 0;
   }

   .contact-row {
      padding-bottom: 40px;

      .row-head {
         display: grid;
         grid-auto-flow: column;
         grid-template-columns: 25px auto;
         padding-bottom: 8px;

         .img-cont {
            display: flex;
            align-items: center;
         }

         .text-cont {
            display: flex;
            align-items: center;
            p {
               font-size: 18px;
               padding-left: 20px;
               font-weight: normal;
            }
         }
      }
      a {
         color: var(--brand-color);
         text-decoration: none;
         font-weight: bold;
         font-size: 18px;
      }
   }
}
