@font-face {
   font-family: "Proxima Nova";
   src: url("../assets/fonts/ProximaNova-LightItalic.otf") format("opentype");
   font-weight: lighter;
}
@font-face {
   font-family: "Proxima Nova";
   src: url("../assets/fonts/Proxima-Nova-Light.otf") format("opentype");
   font-weight: lighter;
   font-style: italic;
}
@font-face {
   font-family: "Proxima Nova";
   src: url("../assets/fonts/Proxima-Nova-Regular.otf") format("opentype");
   font-weight: normal;
}
@font-face {
   font-family: "Proxima Nova";
   src: url("../assets/fonts/ProximaNova-RegItalic.otf") format("opentype");
   font-weight: normal;
   font-style: italic;
}
@font-face {
   font-family: "Proxima Nova";
   src: url("../assets/fonts/Proxima-Nova-Bold.otf") format("opentype");
   font-weight: bold;
}
@font-face {
   font-family: "Proxima Nova";
   src: url("../assets/fonts/ProximaNova-boldItalic.otf") format("opentype");
   font-weight: bold;
   font-style: italic;
}
@font-face {
   font-family: "Proxima Nova";
   src: url("../assets/fonts/Proxima-Nova-Semibold.otf") format("opentype");
   font-weight: 600;
}

////////////////////////////////////////////////////////////////////////
/// 

@font-face {
   font-family: "Open Sans";
   src: url("../assets/fonts/OpenSans-Light.ttf") format("opentype");
   font-weight: lighter;
   font-style: italic;
}
@font-face {
   font-family: "Open Sans";
   src: url("../assets/fonts/OpenSans-Regular.ttf") format("opentype");
   font-weight: normal;
}
@font-face {
   font-family: "Open Sans";
   src: url("../assets/fonts/OpenSans-Regular.ttf") format("opentype");
   font-weight: normal;
   font-style: italic;
}
@font-face {
   font-family: "Open Sans";
   src: url("../assets/fonts/OpenSans-Bold.ttf") format("opentype");
   font-weight: bold;
}
@font-face {
   font-family: "Open Sans";
   src: url("../assets/fonts/OpenSans-BoldItalic.ttf") format("opentype");
   font-weight: bold;
   font-style: italic;
}
@font-face {
   font-family: "Open Sans";
   src: url("../assets/fonts/OpenSans-SemiBold.ttf") format("opentype");
   font-weight: 600;
}

