@import "../../../base/constants.scss";

.btn-product {
   background-color: white;
   padding: 0;
   border: none;
   border-radius: 1.2rem;
   height: 100%;
   width: 100%;
   position: relative;
   display: flex;
   flex-direction: column;
   align-items: center;
   justify-content: center;
   transition: box-shadow 400ms ease;
   box-shadow: 0px 1px 4px rgba(14, 31, 53, 0.12), 0px 4px 8px rgba(14, 31, 53, 0.08);

   
   .product-info {
      display: none;
   }

   :hover {
      cursor: pointer;
   }

   :active {
      cursor: grabbing;
   }

   &.active {
      box-shadow: 0px 0px 5px var(--brand-color);
      border: 2px solid var(--brand-color);
   }
}

.btn-product img {
   max-width: 95%;
   max-height: 95%;
   width: auto;
   height: auto;
   border-radius: 1.2rem;
   padding: 0.2rem;
}

@media only screen and (max-width: $break-tablet) {
   .btn-product {
      flex-direction: row;
      align-items: center;
      justify-content: center;
      
      .product-info {
         display: flex;
      }
   }

   .btn-product img {
      max-width: 33%;
      max-height: 100%;
      width: auto;
      height: auto;
      border-radius: 1.2rem;
      padding: 0.2rem;
      margin-left: 0.6rem;
   }

   .product-info {
      flex: 1;
      display: flex;
      flex-direction: column;
      text-align: left;
      justify-content: center;
      height: 100%;
      padding: 1rem;
      color: black;
      font-size: 1.2rem;
      word-wrap: break-word;
   }

   .product-name {
      font-weight: normal;

   }
   .variant-name {
         font-weight: bold;
      }

}