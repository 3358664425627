.ui {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    pointer-events: none;
    

    button, a, span {
        pointer-events: initial;
    }
    .ui-header {
        height: auto;
        display: flex;
        flex-direction: column;
    }

    .ui-main {
        overflow-y:hidden;
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;

    }

    .ui-footer {
        flex: none;
        width: 100%;
        align-self: flex-end;
        display: flex;
        flex-direction: column;

        .ui-footer-row {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: flex-end;
            min-height: 3rem;
            position: relative;
            width: 100%;
            padding-top: 1rem;
        }

    }


}